import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/components/loader/loader.service';
import { ApiResponse } from 'src/app/constants/interfaces';
import { environment } from 'src/environments/environment';
// import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private _apiUrl1!: string;
  private _apiUrl2!: string;
  private _apiUrl3!: string;
  constructor(private _http: HttpClient, private _loaderService: LoaderService) {
    this._apiUrl1 = environment.API_BASE_PATH;
    this._apiUrl2 = environment.API_BASE_PATH_2;
    this._apiUrl3 = environment.API_BASE_PATH_3;
  }
  post<T>(url: any, data: any, customApiUrl?: number, loader = true,): Observable<any> {

    if (loader) {
      this._loaderService.show();
    }
    let finalApiUrl = '';
    if (customApiUrl === 2) {
      finalApiUrl = this._apiUrl2 + url;
    } else if (customApiUrl === 3) {
      finalApiUrl = this._apiUrl3 + url;

    } else {
      finalApiUrl = this._apiUrl1 + url;
    }
    return this._http.post<any>(finalApiUrl, data);
  }
  get<T>(url: any, httpParams?: any, loader = true, customApiUrl?: number): Observable<ApiResponse<T>> {
    if (loader) {
      this._loaderService.show();
    }
    let finalApiUrl = '';
    if (customApiUrl === 2) {
      finalApiUrl = this._apiUrl2 + url;
    } else if (customApiUrl === 3) {
      finalApiUrl = this._apiUrl3 + url;

    } else {
      finalApiUrl = this._apiUrl1 + url;
    }
    return this._http.get<ApiResponse<T>>(finalApiUrl, {
      params: httpParams,
    });
  }
  put<T>(url, data?, loader = true, customApiUrl?: number): Observable<ApiResponse<T>> {
    if (loader) {
      this._loaderService.show();
    }
    let finalApiUrl = '';
    if (customApiUrl === 2) {
      finalApiUrl = this._apiUrl2 + url;
    } else if (customApiUrl === 3) {
      finalApiUrl = this._apiUrl3 + url;

    } else {
      finalApiUrl = this._apiUrl1 + url;
    }
    return this._http.put<ApiResponse<T>>(finalApiUrl, data);
  }

  delete<T>(url, data?, params?, loader = true, customApiUrl?: number): Observable<ApiResponse<T>> {
    if (loader) {
      this._loaderService.show();
    }

    let finalApiUrl = '';
    if (customApiUrl === 2) {
      finalApiUrl = this._apiUrl2 + url;
    } else if (customApiUrl === 3) {
      finalApiUrl = this._apiUrl3 + url;

    } else {
      finalApiUrl = this._apiUrl1 + url;
    }
    return this._http.delete<ApiResponse<T>>(finalApiUrl, {
      body: data,
      params: params
    });
  }
}
