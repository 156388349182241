export const VALIDATION_MESSAGES = {
  'confirm password': {
    matchPassword: "Confirmation password doesn't match the new password.",
  },
};
export const END_TIME_RE_SELECT = "Please re-select the event start time!";
export const START_TIME_RE_SELECT = "Please re-select the event end time!";


export const OFFLINE = "Connection lost! You are not connected to Internet";
export const ONLINE = "Back to online";
export const NO_INTERNET = "No Internet Connection";
export const TIME_OUT = "Connection timed out. Please retry";
export const INTERNAL_SERVER_ERROR =
  "Couldn't connect to the server. Please try again";
export const INVALID_ID_ERROR = "Invalid ID";
export const VERIFICATION_CODE = "Please enter the verification code.";
export const SOMETHING_WRONG = "Something went wrong. Please try again later.";
export const NO_ACTION = "You can't perform this action";
export const EVENT_EDIT_DENY = "This event can't be edited";
export const EVENT_DELETE_DENY = "This event can't be deleted";
export const EVENT_DRAFT = "Event saved in draft successfully";
export const EVENT_STATUS_CHANGE_DENY = "Event status can't be changed.";
export const LISTING_COMMON_MESSAGES = {
  ACTIVE_TITLE: "Unblock Confirmation",
  ACTIVATE_TITLE: "Active Confirmation",
  BLOCK_TITLE: "Block Confirmation",
  INACTIVATE_TITLE: "Inactive Confirmation",
  DELETE_TITLE: "Delete Confirmation",
  ACTIVE_MSG: "Are you sure you want to unblock this user?",
  ACTIVATE_MSG: "Are you sure you want to activate?",
  BLOCK_MSG: "Are you sure you want to block this user?",
  INACTIVATE_MSG: "Are you sure you want to deactivate?",
  DELETE_MSG: "Are you sure you want to delete?",
  MARK_POPULAR_TITLE: "Mark as popular?",
  UN_MARK_POPULAR_TITLE: "Remove from popular?",
  MARK_POPULAR: "Are you sure you want to mark this as popular?",
  UN_MARK_POPULAR: "Are you sure you want to remove this from popular?",

  MARK_ARCHIVE_TITLE: "Mark as archived?",
  UN_MARK_ARCHIVE_TITLE: "Remove from archived?",
  MARK_ARCHIVE: "Are you sure you want to mark this as archived?",
  UN_MARK_ARCHIVE: "Are you sure you want to remove this from archived?",

};

export const TIME_RESET_MESSAGE = {
  startTime: 'Please re-select event start time.',
  endTime: 'Please re-select event end time.'
}

export const EVENT_MANAGEMENT_CONFIRMATION_MESSAGES = {
  block: {
    message: "Are you sure you want to block this user?",
    title: "Block event?",
  },
  unblock: {
    message: "Are you sure you want to unblock this user?",
    title: "Unblock event?",
  },
  delete: {
    message: "Are you sure you want to delete",
    title: "Delete event?",
  },
  verify: {
    message: "Are you sure you want to verify this Event?",
    title: "Verify the Event?",
  },
  unverify: {
    message: "Are you sure you want to unverify this Event?",
    title: "Unverify the Event?",
  },
  report: {
    message: "Are you sure you want to report this Event?",
    title: "Report the Event?",
  },
  unreport: {
    message: "Are you sure you want to unreport this Event?",
    title: "Unreport the Event?",
  },
  markArchive: {
    message: LISTING_COMMON_MESSAGES.MARK_ARCHIVE,
    title: LISTING_COMMON_MESSAGES.MARK_ARCHIVE_TITLE,
  },
  unMarkArchive: {
    message: LISTING_COMMON_MESSAGES.UN_MARK_ARCHIVE,
    title: LISTING_COMMON_MESSAGES.UN_MARK_ARCHIVE_TITLE,
  },
};

export const DATE_TYPES = {
  SHORT_TIME: "h:mm a",
  MEDIUM_DATE: "MMM d, yyyy",
  FULL_DATE: "EE, MMM d, yyyy",
  VALIDITY_TIME: "MMM d, y - hh:mm:ss a",
  // DATE_WITH_TIME: "MMM d, y - hh:mm a",
  DATE_WITH_TIME: "d/MMM/yy-hh:mm a",
};
export const BANNER_TEMPLATE_TYPE = [
  { name: "Before Login", value: "BEFORE_LOGIN" },
  { name: "After Login", value: "AFTER_LOGIN" },
  { name: "Both", value: "BOTH" },
];




export const COMMON_CONFIRMATION_MESSAGES = {
  block: {
    message: LISTING_COMMON_MESSAGES.INACTIVATE_MSG,
    title: LISTING_COMMON_MESSAGES.INACTIVATE_TITLE,
  },
  unblock: {
    message: LISTING_COMMON_MESSAGES.ACTIVATE_MSG,
    title: LISTING_COMMON_MESSAGES.ACTIVATE_TITLE,
  },
  delete: {
    message: LISTING_COMMON_MESSAGES.DELETE_MSG,
    title: LISTING_COMMON_MESSAGES.DELETE_TITLE,
  },
  markPopular: {
    message: LISTING_COMMON_MESSAGES.MARK_POPULAR,
    title: LISTING_COMMON_MESSAGES.MARK_POPULAR_TITLE,
  },
  unMarkPopular: {
    message: LISTING_COMMON_MESSAGES.UN_MARK_POPULAR,
    title: LISTING_COMMON_MESSAGES.UN_MARK_POPULAR_TITLE,
  },
  markArchive: {
    message: LISTING_COMMON_MESSAGES.MARK_ARCHIVE,
    title: LISTING_COMMON_MESSAGES.MARK_ARCHIVE_TITLE,
  },
  unMarkArchive: {
    message: LISTING_COMMON_MESSAGES.UN_MARK_ARCHIVE,
    title: LISTING_COMMON_MESSAGES.UN_MARK_ARCHIVE_TITLE,
  },
};

/**
 * @IMAGE_RELATED_ERROR
 */

export const MAX_IMG_SELECTION = 1;
export const FILE_EXIST = "Selected image is already exist";
export const MAX_IMG_SELECTION_ERR = (count = MAX_IMG_SELECTION) =>
  `You can select upto ${count} images only`;
export const FUTURE_TIME = "Please select future time";

/**
 * @LOGOUT_CONFIRMATION
 */

export const LOGOUT_CONFIRMATION_MESSAGE = "Are you sure you want to logout?";
export const LOGOUT_Q = "Logout?";


export const BUTTON_TYPE = {
  CANCEL: "Cancel",
  CONFIRM: "Confirm",
};

/**
 * @S3_BUCKET_ERROR
 */

export const S3_BUCKET_ERROR = "Network Failure or Sync up your system clock";

export const ORGANIZATION_CHANGE_CONFIRMATION_MESSAGE = {
  title: "Heads Up! You're switching profiles",
  message: "Looks like you're about to jump to another profile. This switch will update your access levels and visibility within the app. You can always switch profiles whenever necessary.Tap 'Confirm' if that's cool, or 'Cancel' if you wanna stay put."
}

export const MAKE_PRIVATE_EVENT_CONFIRMATION_MESSAGE = {
  title: "Quick FYI! You're switching all events to private.",
  message: 'You’re getting ready to switch this event, along with all of the sub-events to private. None of these events will appear in the public search, however, this can still be changed for each sub-event.',
  subText: "Tap 'Confirm' if that's cool, or 'Cancel' if you wanna stay put."
}

export const MAKE_PUBLIC_EVENT_CONFIRMATION_MESSAGE = {
  title: "Quick FYI! You're making all events public.",
  message: 'You’re getting ready to switch this event, along with all of the sub-events to public. All of these events will now appear in the public search, however, this can still be changed for each sub-event.',
  subText: "Tap Confirm' if that's cool, or 'Cancel' if you wanna stay put."
}

export const MAKE_PRIVATE_EVENT_CONFIRMATION_MESSAGE_CLASS = {
  title: "Quick FYI! You're switching all classes to private.",
  message: 'You’re getting ready to switch this event, along with all of the classes to private. None of these events will appear in the public search, however, this can still be changed for each class.',
  subText: "Tap 'Confirm' if that's cool, or 'Cancel' if you wanna stay put."
}

export const MAKE_PUBLIC_EVENT_CONFIRMATION_MESSAGE_CLASS = {
  title: "Quick FYI! You're making all classes public.",
  message: 'You’re getting ready to switch this event, along with all of the classes to public. All of these events will now appear in the public search, however, this can still be changed for each class.',
  subText: "Tap Confirm' if that's cool, or 'Cancel' if you wanna stay put."
}

export const EXPORT_TABLE_LIST_MESSAGE = "It looks like you're trying to export, but no data has been selected."
export const DELETE_TABLE_LIST_MESSAGE = "It looks like you're trying to delete, but no data has been selected."
export const ARCHIVE_TABLE_LIST_MESSAGE = "It looks like you're trying to archive, but no data has been selected."
export const VIEW_TABLE_LIST_MESSAGE = "It looks like you’re trying to view a ticket, but no data has been selected."
export const REFUND_TABLE_LIST_MESSAGE = "It looks like you're trying to issue a refund, but no data has been selected."
export const DECLINE_REFUND_TABLE_LIST_MESSAGE = "Looks like you’re trying to decline refund, but there’s no data selected."
export const ORGANIZATION_REQUIRED = "Please select organization"

