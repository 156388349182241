import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Subject, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { DASHBOARD, LOGIN } from 'src/app/constants/routes';
import { PROFILE_DETAIL } from 'src/app/constants/api-end-points';
import { email, organizerRequest, socialId, userId, } from 'src/app/constants/storage-keys';
import { ToastService } from 'src/app/components/toast-notification/toast.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  
user:any;
  profileDetail: any;
  profileData = new Subject<any>();

  constructor(private _http: HttpService, private _router: Router, private _toast: ToastService,private _authService:SocialAuthService) {
  }

  setUserToken(token: any) {
    localStorage.setItem("session_token", token);
  }

  get token() {
    return localStorage.getItem("session_token");
  }

  get userId() {
    return atob(localStorage.getItem(userId));
  }
  get socialId() {
    
    return atob(localStorage.getItem(socialId));
  }
  signOut(): void {
    this._authService.signOut().then(() => {
      this.user = null;
    });
  }
  logout() {
    localStorage.removeItem(email);
    localStorage.removeItem(organizerRequest);
    localStorage.removeItem('session_token');
    setTimeout(() => {
      this.profileDetail = null;
    }, 1000); // this is used for avoid random reflection on side bar if logout
    this._router.navigate([LOGIN.fullUrl]);

  }

  async loginSuccessfully(response: any, responeFrom = "") {
    const data = response.result;
    console.log(response);
    
    this.setUserToken(data.accessToken);
    localStorage.setItem(userId, btoa(data._id));
    this._router.navigate([DASHBOARD.fullUrl]);
    this._toast.success(response.message)
  }
  async socialloginSuccessfully(response: any, responeFrom = "") {
    const data = response.result;
    this.setUserToken(data.accessToken);
    localStorage.setItem(socialId, btoa(data.socialId));
    this._router.navigate([DASHBOARD.fullUrl]);
    this._toast.success(response.message)
  }



  getProfileDetail(showLoader = true) {
    return new Promise((resolve, reject) => {
      if (this.profileDetail) {
        resolve(this.profileDetail);
      } else {

        firstValueFrom(this._http
          .get(PROFILE_DETAIL,'',true,2))
          .then(
            (response: any) => {
              if (response?.result?.status === "blocked") {
                this.logout();
                reject();
              }
              
              this.profileDetail = response.result;
              console.log(this.profileDetail);
              localStorage.setItem(organizerRequest, btoa(this.profileDetail.organizerRequest));
              resolve(this.profileDetail);
            },
            (error) => {
              reject(error);
              this.logout();

            }
          )
          .catch((error) => {
            this.logout();
          });
      }
    });
  }


  getTimeZone() {
    let date = new Date();
    let offset = date.getTimezoneOffset() * -1;
    return offset * 60 * 1000;
  }


}
