const NOTIFICATION_BASE: string = "notification";
const ADMIN_BASE: string = "admin";
const BOOKING: string = "booking";

const USER_SERVICE = "admin/api/v1/organizer";
const APP_USER_SERVICE = "/user/api/v1/";
const PAYMENT_SERVICE = "/payment/api/v1/";
const EVENT_USER_SERVICE = "/event/api/v1";
const ORGANIZER_SERVICE = "/organizer/api/v1";
const USER_SERVICE_FOR_Admin = "admin/api/v1";
const COMMON_BASE = "/common/api/v1/common";

const USER_TYPE = `${USER_SERVICE}`;

export const IMAGE_UPLOAD_API = `/event/api/v1/event/upload-media`;

/**
 * @ACCOUNT_RELATED_END_POINTS
 */
export const LOGIN_API = `${APP_USER_SERVICE}/user/login`;
export const RESEND_OTP = `${APP_USER_SERVICE}/user/resend-otp`;
export const SOCIAL_LOGIN_API = `${APP_USER_SERVICE}user/organizer-social-login`;
export const LOGOUT_API = `${APP_USER_SERVICE}/user/logout`;
export const FORGOT_PASSWORD_API = `${APP_USER_SERVICE}/user/check-mobile-for-forgot-password`;
export const RESET_PASSWORD_API = `${APP_USER_SERVICE}/user/password-reset`;
export const CHANGE_PASSWORD_API = `${USER_TYPE}/change-password`;
export const VERIFY_OTP_API = `${APP_USER_SERVICE}user/verify-otp`;
export const USER_INFO_API = `${USER_TYPE}`;
export const ONBOARDING_API = `${USER_TYPE}/profile-complete`;
export const VERIFY_OTP_FROM_PROFILE_API = `${APP_USER_SERVICE}user/mobile-verification`;

/**
 *  @ORGANIZATION related endpoints
 */

export const ORGANIZATION = `${APP_USER_SERVICE}organization`;
export const ORGANIZATION_LISTING_API = `${ORGANIZATION}/panel-organization-list`;
export const ORGANIZATION_USERS_LISTING_API = `${ORGANIZATION}/organization-user-list`;
export const ORGANIZATION_DETAIL_API = `${ORGANIZATION}/get-organization-detail`;
export const CREATE_ORGANIZATION_API = `${ORGANIZATION}/create-organization`;
export const UPDATE_ORGANIZATION_API = `${ORGANIZATION}/update-organization`;
export const UPDATE_REFUND_SETTINGS_API = `${ORGANIZATION}/update-refund-setting`;
export const UPDATE_TICKET_SETTINGS_API = `${ORGANIZATION}/update-ticket-setting`;

export const FAVORITE = `${APP_USER_SERVICE}favorite-user`;
export const GET_FAVORITE_USER_DETAILS_API = `${FAVORITE}/favorite-user-detail`;
export const GET_FAVORITE_USER_STATUS_API = `${FAVORITE}/favorite-user-update-status`;
export const GET_FAVORITE_USER_EVENT_LIST_API = `/organizer/api/v1/organizer/favorite-user-event-list`;
export const UNLINK_USER_FORM_EVENT_API = `${ORGANIZER_SERVICE}/organizer/unlink-user-from-event`;
export const MANAGE_ATTENDEE_LIST_API = `${ORGANIZER_SERVICE}/organizer/event-attendees-list`;
export const UPDATE_TAX_EXEMPTION_DETAILS = `${APP_USER_SERVICE}user/update-non-profit-excemtion-detail`;
export const CREATE_ORGANIZER_ACCOUNT = `${PAYMENT_SERVICE}payment/create-organizer-account`;

/**
 *  @ORDER related endpoints
 */

export const ORDER_LISTING_API = `${ORGANIZER_SERVICE}/organizer/organizer-order-list`;
export const ORDER_DETAILS_API = `${ORGANIZER_SERVICE}/organizer/organizer-order-detail`;
export const ORDER_TICKET_LISTING_API = `${ORGANIZER_SERVICE}/organizer/organizer-order-ticket-list`;
export const REFUND_REASON_API = `${COMMON_BASE}/get-reason-list`;
export const DECLINE_REFUND_API = `${ORGANIZER_SERVICE}/organizer/decline-refund-request`;
export const REFUND_PROCESSED_API = `${ORGANIZER_SERVICE}/organizer/refund-processed`;

/**
 * @Category Relate End Points
 */

export const CATEGORY = `${USER_SERVICE_FOR_Admin}/category`;
export const CATEGORY_LIST_API = `${CATEGORY}/get-category-list`;
export const CATEGORY_LIST_API_FOR_CREATE_EVENT = `/user/api/v1/category/category-list-with-pagination`;
export const UPDATE_CATEGORY_STATUS_API = `${CATEGORY}/update-status-category`;
export const CATEGORY_DETAIL_API = `${CATEGORY}/get-category-detail`;
export const CATEGORY_UPDATE_API = `${CATEGORY}/update-category`;
export const CATEGORY_CREATE_API = `${CATEGORY}/create-category`;
export const POPULAR_CATEGORIES_LIST_API = `${CATEGORY}/get-popular-category-list`;
export const MARK_UN_MARK_POPULAR_CATEGORY_API = `${CATEGORY}/mark-as-popular-category`;
export const SEND_CATEGORY_ADD_REQUEST_API = `/event/api/v1/event/category-request`;

/**
 * @SUBCATEGORY Relate End Points
 */

export const SUBCATEGORY = `${USER_SERVICE_FOR_Admin}/subcategory`;
export const SUBCATEGORY_LIST_API = `${SUBCATEGORY}/get-subcategory-list`;
export const SUBCATEGORY_LIST_API_FOR_CREATE_EVENT = `/subcategory/api/v1/subcategory/sub-category-list-with-pagination`;
export const UPDATE_SUBCATEGORY_STATUS_API = `${SUBCATEGORY}/update-subcategory-status`;
export const SUBCATEGORY_DETAIL_API = `${SUBCATEGORY}/get-sub-category-detail`;
export const ASSOCIATEDCATEGORY_LIST_API = `${SUBCATEGORY}/associate-category-list`;
export const SUBCATEGORY_UPDATE_API = `${SUBCATEGORY}/update-subcategory`;
export const SUBCATEGORY_CREATE_API = `${SUBCATEGORY}/create-subcategory`;
export const SUBCATEGORY_DELETE_API = `${SUBCATEGORY}/delete-subcategory`;
export const POPULAR_SUBCATEGORIES_LIST_API = `${SUBCATEGORY}/popular-sub-category-list`;
export const MARK_UN_MARK_POPULAR_SUBCATEGORY_API = `${SUBCATEGORY}/mark-as-popular-sub-category`;
export const SEND_SUBCATEGORY_ADD_REQUEST_API = `/subcategory/api/v1/subcategory/subcategory-request`;

/**
 * @TAG_MANAGEMENT Relate End Points
 */

export const TAG = `${USER_SERVICE_FOR_Admin}/tag`;
export const TAG_LIST_API = `/tag/api/v1/tag/organizer-panel-tag-list`;
// export const ASSOCIATEDCATEGORY_LIST_API = `${SUBCATEGORY}//associate-category-list`;
export const TAG_UPDATE_API = `${TAG}/update-tag`;
export const TAG_CREATE_API = `${TAG}/create-tag`;
export const TAG_DELETE_API = `${TAG}/delete-tag`;
export const REQUEST_TAG_API = `/tag/api/v1/tag/tag-request`;

/**
 * @Event Relate End Points
 */

export const EVENT = `${USER_SERVICE}`;
export const EVENT_LIST_API = `/organizer/api/v1/organizer/panel-organizer-event-list`;
export const EVENT_DETAIL_API = `/organizer/api/v1/organizer/panel-organizer-event-detail`;
export const EVENT_STATUS_UPDATE_API = `${EVENT}/update-event-status`;
export const CREATE_SINGLE_EVENT_API = `/event/api/v1/event/create-single-event`;
export const CREATE_CLASS_CREATOR_EVENT_API = `/event/api/v1/event/create-class-creator`;
export const UPDATE_SINGLE_EVENT_API = `${EVENT_USER_SERVICE}/event/update-single-event`;
export const UPDATE_MULTIPLE_EVENT_API = `${EVENT_USER_SERVICE}/event/update-multi-event`;
export const UPDATE_CLASS_CREATOR_API = `/event/api/v1/event/update-class-creator`;
export const UPDATE_EVENT_TICKET_SCANNER_ORGANIZER_API = `${EVENT}/update-organizer-and-scanners`;
export const EVENT_DETAIL_FOR_EDIT_EVENT_API = `${EVENT_USER_SERVICE}/event/event-drop-down-values`;
export const DELETE_EVENT_API = `/event/api/v1/event/delete-event`;
export const ARCHIVE_EVENT_API = `/event/api/v1/event/archive-event`;
export const CHECK_EDIT_AND_DELETE_EVENT_AVAILABILITY = `/event/api/v1/event/check-event-edit-delete-availability`;
export const EVENT_REGISTERED_USER_LIST = `${EVENT}/event-registered-users`;
export const EVENT_SAVE_AS_DRAFT_API = `${EVENT_USER_SERVICE}/event/save-as-draft`;
export const REMOVE_EVENT_FROM_DRAFT_API = `/event/api/v1/event/remove-from-draft`;
export const CHECK_DUPLICATE_EVENT_TITLE_API = `${EVENT_USER_SERVICE}/event/check-event-title-duplicacy`;
export const CREATE_MULTIPLE_EVENT_API = `${EVENT_USER_SERVICE}/event/create-multiple-event`;
export const CANCEL_EVENT_API = `${EVENT_USER_SERVICE}/event/cancel-event`;
export const CALCULATE_SERVICE_FEE_API = `${EVENT_USER_SERVICE}/event/calculate-service-fee`;
export const ARCHIVE_OR_DELETE_EVENT_API = `${ORGANIZER_SERVICE}/organizer/organizer-panel-event-action`;

export const EVENT_SAVE_AS_DRAFT_API_CLASS = `/event/api/v1/event/save-as-draft`;
export const REMOVE_EVENT_FROM_DRAFT_API_CLASS = `/event/api/v1/event/remove-from-draft`;

// export const TAG_CREATE_API = `${TAG}/create-tag`;
// export const TAG_DELETE_API = `${TAG}/delete-tag`;

/**
 * @BANNER_MANAGEMENT Relate End Points
 */

export const BANNER = `${USER_SERVICE}/banner`;
export const BANNER_LIST_API = `${BANNER}/get-banner-list`;
export const BANNER_STATUS_UPDATE_API = `${BANNER}/update-banner-status`;
export const BANNER_CREATE_API = `${BANNER}/create-banner`;
export const BANNER_DELETE_API = `${BANNER}/delete-banner`;
export const BANNER_DETAIL_API = `${BANNER}/get-banner-detail`;
export const BANNER_UPDATE_API = `${BANNER}/update-banner`;

/**
 * @PROFILE_MANAGEMENT
 */

export const PROFILE = `${APP_USER_SERVICE}user`;
export const PROFILE_DETAIL = `${PROFILE}/get-user-detail`;
export const CHECK_USERNAME_AVAILABILITY = `${PROFILE}/check-username`;
export const UPDATE_PROFILE = `${PROFILE}/update-profile`;
export const UPDATE_PASSWORD = `${PROFILE}/change-password`;
export const MOBILE_NUMBER_CHECK = `${PROFILE}/check-mobile-for-verification`;

/**
 * @CONTENT_MANAGEMENT
 */

export const TERM_AND_POLICY = `/user/api/v1/content/get-content-detail`;

export const CONTENT = `${USER_SERVICE}/content`;
export const CONTENT_DETAIL_API = `${CONTENT}/get-content-detail`;
export const CONTENT_UPDATE_API = `${CONTENT}/update-content`;

export const FAQ = `${USER_SERVICE}/faq`;
export const FAQ_LISTING_API = `${FAQ}/get-faqs`;
export const FAQ_UPDATE_API = `${FAQ}/update-faq`;
export const FAQ_UPDATE_STATUS_API = `${FAQ}/update-faq-status`;
export const FAQ_ADD_API = `${FAQ}/add-faqs`;
export const FAQ_DELETE_API = `${FAQ}/delete-faq`;

/**
 * @EVENT_ORGANIZER
 */
export const EVENT_ORGANIZER = `${USER_SERVICE_FOR_Admin}/user`;
export const EVENT_ORGANIZER_LIST_API = `${EVENT_ORGANIZER}/get-organizer-list`;
export const EVENT_ORGANIZER_DETAIL_API = `${EVENT_ORGANIZER}/get-user-detail`;
export const EVENT_ORGANIZER_STATUS_UPDATE_API = `${EVENT_ORGANIZER}/update-user-status`;
export const EVENT_ORGANIZER_CREATED_EVENT_LIST_API = `${EVENT_ORGANIZER}/get-organizer-created-event-list`;
export const ORGANIZATION_LIST_API = `/user/api/v1/organization/panel-organization-list`;
export const SEARCH_EVENT_ORGANIZER_API = `/event/api/v1/event/get-event-organizers`;

/**
 * @USER_MANAGEMENT
 */
export const USER = `${USER_SERVICE_FOR_Admin}/user`;
export const USER_LIST_API = `${USER}/get-user-list`;
export const USER_ORGANIZER_LIST_API = `${USER}/get-user-organizer-list`;
export const USER_BOOKED_EVENT_LIST_API = `${USER_SERVICE}/${BOOKING}/get-user-booking-list`;
export const USER_BOOKED_EVENT_DETAIL_API = `${USER_SERVICE}/${BOOKING}/get-booking-detail`;
export const USER_DETAIL_API = `${USER}/get-user-detail`;
export const USER_STATUS_UPDATE_API = `${USER}/update-user-status`;
export const GET_FAVORITE_USER_LIST_API = `/user/api/v1/favorite-user/get-favorite-user-list`;
export const SEARCH_TICKET_SCANNER_LIST_API = `/event/api/v1/event/get-ticket-scanners`;
export const SEARCH_ORGANIZER_USER_LIST_API = `/event/api/v1/event/get-event-organizers`;
export const MARK_FAVORITE_USER_API = `/user/api/v1/favorite-user/add-favorite-user`;
export const SEND_USER_INVITE_API = `/user/api/v1/favorite-user/send-invite`;
export const DELETE_FAVORITE_USER = `/user/api/v1/favorite-user/delete-favorite-user`;

/**
 * @DASHBOARD
 */

export const DASHBOARD = `${USER_SERVICE}/dashboard`;
export const DASHBOARD_USER_AND_ORGANIZER_API = `${DASHBOARD}/get-recent-users-organizers`;
export const DASHBOARD_BOOKING_EARNING_API = `${USER_SERVICE}/${BOOKING}/get-booking-earning`;

export const DASHBOARD_COUNT_API = `/organizer/api/v1/organizer/organizer-dashboard`;
export const DASHBOARD_UPCOMING_EVENT_API = `/organizer/api/v1/organizer/dashboard-upcoming-event-list`;

/**
 * SUB_ADMIN
 */

export const SUB_ADMIN = `${USER_SERVICE}/${ADMIN_BASE}`;
export const CREATE_SUB_ADMIN_API = `${SUB_ADMIN}/create-sub-admin`;
export const GET_SUB_ADMIN_LIST_API = `${SUB_ADMIN}/get-sub-admin-list`;
export const GET_SUB_ADMIN_DETAIL_API = `${SUB_ADMIN}/get-sub-admin-detail`;
export const UPDATE_SUB_ADMIN_STATUS_API = `${SUB_ADMIN}/update-sub-admin-status`;
export const UPDATE_SUB_ADMIN_DETAIL_API = `${SUB_ADMIN}/update-sub-admin`;
export const DELETE_SUB_ADMIN_API = `${SUB_ADMIN}/delete-sub-admin`;

/**
 * NOTIFICATION
 */

export const GET_NOTIFICATION_LIST = `${USER_SERVICE}/${NOTIFICATION_BASE}/get-notification-list`;
export const GET_NOTIFICATION_DETAIL = `${USER_SERVICE}/${NOTIFICATION_BASE}/get-notification-detail`;
export const SEND_NOTIFICATION = `${USER_SERVICE}/${NOTIFICATION_BASE}/send-push-notification`;

/**
 * CLASS CREATOR
 */
export const GET_BUSINESS_TYPE = `${COMMON_BASE}/get-class-type`;
