import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';
import { INTERNAL_SERVER_ERROR, NO_INTERNET } from 'src/app/constants/messages';
import { AuthService } from './auth/auth.service';
import { map, catchError, finalize, timeout } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ToastService } from 'src/app/components/toast-notification/toast.service';
import { LoaderService } from 'src/app/components/loader/loader.service';
export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: 'root',
})
export class InterceptorService {
  excludedUrls = ['https://maps.googleapis.com/maps/api/timezone/json'];

  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private _store: StorageService,
    private _router: Router,
    private _auth: AuthService,
    private _toast: ToastService,
    private _ls: LoaderService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const timeoutValue = Number(req.headers.get('timeout')) || this.defaultTimeout;
    const isExcluded = this.excludedUrls.some(url => req.url.includes(url));

    if (isExcluded) {
      // If request URL matches an excluded URL, bypass the interceptor logic
      return next.handle(req);
    }
    let authReq = req.clone({ setHeaders: this._auth.authorization() });
    
    return next.handle(authReq).pipe(
      // retryWithBackOff(),
      timeout(timeoutValue),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this._ls.hide();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorState(error, error.error);
        return throwError(error.error ? error.error : error);
      }),

      finalize(() => {
        this._ls.hide();
      })
    );
  }

  errorState(error: any, data?: any) {
    this._ls.hide();
    if (error.name === "TimeoutError") {
      this._toast.error(error.message);
      return
    }
    if (error.name === "HttpErrorResponse" && data.type == 'error') {
      this._toast.error(error.statusText);
      return
    }
    if (data.status == 0) {
      if (!navigator.onLine) {
        this._toast.error(NO_INTERNET);
      } else {
        this._toast.error(INTERNAL_SERVER_ERROR);
      }
    } else if (data.status == 401) {
      this._toast.error(data ? data.message : error.message);
      this._store.logout();
      this._store.signOut();
    } else if (data.status == 404) {
      this._toast.error(data ? data.message : error.message);
    } else if (data.status === 423) {
      this._toast.error(data ? data.message : error.message);
      this._store.logout();
      this._store.signOut();

    } else if (data.status == 404) {
      this._toast.error(data ? data.message : error.message);
      this._router.navigate(['404']);
    } else if (data.status == 409) {
      this._toast.error(data ? data.message : error.message);
      // this._store.logout();
    }
    else {
      this._toast.error(data ? (data.errors || data.message) : error.message);
    }
  }
}
